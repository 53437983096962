import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, take } from 'rxjs';

import { AnalyticEvent } from '@app/core/analytics.service';
import { AppointmentAnalyticsProperty } from '@app/core/appointment-analytics-base.service';
import { LinksService } from '@app/core/links.service';
import { FLOW_APPOINTMENT_BOOKING } from '@app/core/mixpanel.constants';
import { InsuranceCaptureService } from '@app/shared/insurance-capture/insurance-capture.service';
import { PaymentCaptureService, PaymentMethodData } from '@app/shared/payment-capture/payment-capture.service';

import { AppointmentAnalyticsService } from '../appointment-analytics.service';

export enum PaymentStep {
  CONFIRM_METHOD = 'confirm-method',
  COPAY_REQUIRED = 'copay-required',
  SELECT_METHOD = 'select-method',
  ADD_METHOD = 'add-method',
}

export enum ManagePaymentMethodsQueryParams {
  SOURCE = 'source',
  CONFIRMATION_ID = 'confirmationId',
  IS_NEW_INSURANCE = 'new',
}

export enum ManagePaymentMethodsSource {
  FALLBACK = 'fallback',
  INSURANCE = 'insurance',
  REVIEW = 'review',
  CONFIRMATION = 'confirmation',
}

export const ManagePaymentMethodsSourcePropertyMap: Record<ManagePaymentMethodsSource, string | undefined> = {
  [ManagePaymentMethodsSource.FALLBACK]: undefined,
  [ManagePaymentMethodsSource.INSURANCE]: AppointmentAnalyticsProperty.AddInsurancePagePreBookingModalModule,
  [ManagePaymentMethodsSource.REVIEW]: AppointmentAnalyticsProperty.AppointmentSelectedPage,
  [ManagePaymentMethodsSource.CONFIRMATION]: AppointmentAnalyticsProperty.AppointmentConfirmationPageModule,
} as const;

@Component({
  selector: 'om-manage-payment-methods',
  templateUrl: './manage-payment-methods.component.html',
  styleUrls: ['./manage-payment-methods.component.scss'],
})
export class ManagePaymentMethodsComponent implements OnInit {
  readonly PaymentStep = PaymentStep;
  currentStep: PaymentStep;
  showSkip: boolean;
  showBack: boolean;
  source: ManagePaymentMethodsSource;
  sourceRoute: string;
  isNewInsurance: boolean;
  historyStack: PaymentStep[] = [];
  defaultCopayMethod: PaymentMethodData | null;
  paymentMethods: PaymentMethodData[];
  currentPaymentMethod: PaymentMethodData;
  canSetDefault: boolean;
  copay: number;
  isFlagOptional: boolean;
  isFlagM2Enabled: boolean;
  isFlagM2Optional: boolean;
  protected loading = true;

  constructor(
    private readonly paymentCaptureService: PaymentCaptureService,
    private readonly insuranceCaptureService: InsuranceCaptureService,
    private readonly router: Router,
    private readonly links: LinksService,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly analyticsService: AppointmentAnalyticsService,
  ) {}

  ngOnInit() {
    const params = this.route.snapshot.queryParamMap;
    this.source = params.get(ManagePaymentMethodsQueryParams.SOURCE) as ManagePaymentMethodsSource;
    const confirmationId = params.get(ManagePaymentMethodsQueryParams.CONFIRMATION_ID);
    this.isNewInsurance = params.get(ManagePaymentMethodsQueryParams.IS_NEW_INSURANCE) === 'true';

    this.setSourceRoute(confirmationId);

    combineLatest([
      this.insuranceCaptureService.getPrimaryInsurance$(),
      this.paymentCaptureService.getPaymentMethods$(),
      this.paymentCaptureService.getPaymentCaptureFlagOptional$(),
      this.paymentCaptureService.getPaymentCaptureFlagM2Enabled$(),
      this.paymentCaptureService.getPaymentCaptureFlagM2Optional$(),
    ])
      .pipe(take(1))
      .subscribe(([insurance, paymentMethods, isFlagOptional, isFlagM2Enabled, isFlagM2Optional]) => {
        this.copay = insurance.copay || 0;
        this.canSetDefault = this.source === ManagePaymentMethodsSource.INSURANCE && this.copay > 0;
        this.mapPaymentMethods(paymentMethods);
        this.isFlagOptional = isFlagOptional;
        this.isFlagM2Enabled = isFlagM2Enabled;
        this.isFlagM2Optional = isFlagM2Optional;
        this.loading = false;
        if (!this.currentStep) {
          const initialStep = this.getInitialStep();
          this.goToStep(initialStep);
        }
      });
  }

  goBack() {
    if (this.historyStack.length > 1) {
      // If there's local history, go back within component
      this.historyStack.pop();
      const step = this.historyStack.pop() as PaymentStep;
      this.goToStep(step);
    } else {
      this.location.back();
    }
  }

  goToStep(step: PaymentStep) {
    this.historyStack.push(step);

    // Hide back button on first step if unknown source
    const isFirstStep = step === this.historyStack[0];
    this.showBack = !(this.source === ManagePaymentMethodsSource.FALLBACK && isFirstStep);

    // Show skip if experiment flag enabled, source is insurance, and it's the first step (ignoring Confirm Method page when card is valid)
    this.showSkip =
      (this.isFlagM2Enabled ? this.isFlagM2Optional : this.isFlagOptional) &&
      this.source === ManagePaymentMethodsSource.INSURANCE &&
      step ===
        this.historyStack.find(s => !(s === PaymentStep.CONFIRM_METHOD && !this.currentPaymentMethod?.isExpired));

    this.currentStep = step;
  }

  goToDestination() {
    this.source === ManagePaymentMethodsSource.INSURANCE
      ? this.router.navigate([this.links.appointmentReview])
      : this.router.navigate([this.sourceRoute]);
  }

  refreshPaymentMethods() {
    this.paymentCaptureService
      .getPaymentMethods$()
      .pipe(take(1))
      .subscribe(paymentMethods => this.mapPaymentMethods(paymentMethods));
  }

  trackAnalytics(analytics: AnalyticEvent) {
    this.analyticsService.trackEventWithDefaultProperties({
      flow: FLOW_APPOINTMENT_BOOKING,
      source: ManagePaymentMethodsSourcePropertyMap[this.source],
      ...analytics,
    });
  }

  private mapPaymentMethods(paymentMethods: PaymentMethodData[]) {
    this.paymentMethods = paymentMethods;
    this.defaultCopayMethod = this.paymentMethods.find(method => method.isDefaultCopay);
    this.currentPaymentMethod = this.defaultCopayMethod || paymentMethods?.[0];
  }

  private getInitialStep() {
    if (!this.isFlagM2Enabled) {
      return this.canSetDefault ? PaymentStep.COPAY_REQUIRED : PaymentStep.ADD_METHOD;
    }

    if (this.canSetDefault) {
      if (this.defaultCopayMethod) {
        return PaymentStep.CONFIRM_METHOD;
      } else if (this.isNewInsurance) {
        return PaymentStep.COPAY_REQUIRED;
      }
    }

    return this.paymentMethods.length ? PaymentStep.SELECT_METHOD : PaymentStep.ADD_METHOD;
  }

  private setSourceRoute(confirmationId?: string) {
    if (this.source === ManagePaymentMethodsSource.INSURANCE) {
      this.sourceRoute = this.links.manageInsurance;
    } else if (this.source === ManagePaymentMethodsSource.REVIEW) {
      this.sourceRoute = this.links.appointmentReview;
    } else if (this.source === ManagePaymentMethodsSource.CONFIRMATION && confirmationId) {
      this.sourceRoute = this.links.appointmentConfirmation(confirmationId);
    } else {
      this.source = ManagePaymentMethodsSource.FALLBACK;
      this.sourceRoute = this.links.home;
    }
  }
}
